import { post } from "./base";

const API_SERVICE_QUERY = "/api/Service/V1/query";

export default {
  getAllEdad: (search) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_migr_select_tvna_pesoedadninia",
          type: "string"
        },
        {
          name: "@filtros_AND",
          value: "edadaniosmeses",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: `%${search}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },
  getAllTalla: (search) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_migr_select_tvna_pesotallaninia",
          type: "string"
        },
        {
          name: "@filtros_AND",
          value: "tallacentimetros",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: `%${search}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  }
};
